<script lang="ts">
	import { openTextOverlay } from "svelte-comps/overlay"
	import type { AudioPlayer } from "../../modules/AudioPlayer"
	import NeedClickOverlay from "./NeedClickOverlay.svelte"
	import { RadioController } from "./RadioController"
	import AudioCodeInput from "./AudioCodeInput.svelte"
	import AudioCodePreview from "./AudioCodePreview.svelte"

	let id: string | undefined
	let player: AudioPlayer | undefined
	let hasClicked: boolean

	RadioController.subscribe((ctx) => {
		id = ctx.audioCode
		player = ctx.player
		hasClicked = ctx.hasUserInteracted
	})
</script>

{#if !hasClicked && id}
	<NeedClickOverlay />
{/if}

<div class="radio-player">
	{#if !id}
		<AudioCodeInput />
	{:else}
		<AudioCodePreview />
	{/if}
</div>
<div class="flex-row center-hor">
	<a href="https://old.myluxedo.com/audio_player/{id ?? ''}">access the legacy version</a>
</div>

<style>
	.radio-player {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.radio-player :global(.card) {
		align-items: flex-start;
	}

	.radio-player :global(.space-apart) {
		justify-content: space-between;
		width: 100%;
	}

	.radio-player :global(.heading) {
		line-height: 1em;
		font-size: var(--h2);
		color: var(--color-text-light);
	}

	.radio-player :global(.info) {
		height: 2rem;
		vertical-align: top;
		margin-bottom: 1rem;
		color: var(--color-text);
		font-size: var(--h3);
	}

	.radio-player :global(.error) {
		color: var(--ct-red);
	}

	.radio-player :global(.small) {
		font-size: var(--h3);
	}

	.radio-player :global(.connected-to),
	.radio-player :global(.connect-to),
	.radio-player :global(.current-playback) {
		display: flex;
		flex-direction: column;
	}
	.radio-player :global(.connected-to) {
		margin-bottom: 1rem;
	}

	.radio-player :global(h3) {
		margin: 0 0 0;
		font-size: var(--h1);
		color: var(--color-main);
	}

	.radio-player :global(h3.audio-code) {
		font-size: var(--h2);
	}

	.radio-player :global(.button-container) {
		justify-content: center;
		gap: 1rem;
		margin-bottom: 0;
	}

	.radio-player :global(.button-container button) {
		flex-grow: 1;
	}

	.radio-player :global(.last-update) {
		line-height: 1em;
		font-size: var(--h3);
		color: var(--color-text);
		margin-left: 0.1em;
		padding-bottom: 0.1em;
	}

	.radio-player :global(.input-wrapper) {
		display: flex;
		align-items: center;
	}

	.radio-player :global(.input-wrapper svg) {
		margin-top: 0.2em;
		height: var(--h2);
		width: auto;
		margin-right: 0.2rem;
	}

	.radio-player :global(.input-wrapper .svg-stroke) {
		stroke: var(--color-main);
	}

	.radio-player :global(.player-bottom) {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.radio-player :global(.playback-offset) {
		font-size: var(--h3);
		color: var(--color-text);
		height: 1.5rem;
	}

	a {
		border: none;
	}

	a:hover,
	a:focus-visible {
		text-decoration: underline;
	}

	@media only screen and (max-width: 999px) {
		.radio-player :global(.button-container button) {
			font-size: var(--h3);
		}
	}
</style>
