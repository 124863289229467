<script lang="ts">
	import { Route, Router } from "svelte-routing"
	import App from "./App.svelte"
</script>

<Router>
	<Route path="radio/:id" let:params>
		<App id={params.id} />
	</Route>
	<Route path="radio">
		<App />
	</Route>
</Router>

<style>
</style>
