import { DateTime } from "luxon"

export function extractIdFromUrl(url: string): string | null {
	const regex = /\/project\/(\d+)[A-Z]\.mp3/

	const match = url.match(regex)
	return match ? match[1] : null
}

let audioContext: AudioContext | null = null
function getAudioContext() {
	if (!audioContext) audioContext = new AudioContext()
	return audioContext
}

export class AudioPlayer {
	// Audio URL
	private url: string

	// AudioContext API
	private audioContext: AudioContext
	private audioBuffer: AudioBuffer | null = null
	private sourceNode: AudioBufferSourceNode | null = null

	private declare playbackStart: number
	private declare playbackOffset: number
	private declare playbackRate: number

	// State booleans
	public isPlaying: boolean = false
	public isLoaded: boolean = false // Load Audio promise (only exists when actively loading)
	public name: string

	private declare loadingPromise: Promise<void> | undefined
	declare id: number

	constructor(url: string, name: string) {
		this.audioContext = getAudioContext()

		this.name = name
		this.url = url
		this.id = Date.now()

		this.loadAudio()
	}

	/**
	 * Begins loading and decoding the audio data into the AudioContext api.
	 * @returns Promise which will resolve upon completed load
	 */
	async loadAudio(): Promise<void> {
		if (this.loadingPromise) return this.loadingPromise

		this.loadingPromise = new Promise(async (res, rej) => {
			this.isLoaded = false

			const resolve = (audio: AudioBuffer) => {
				this.isLoaded = true
				this.audioBuffer = audio
				res()
				this.loadingPromise = undefined
			}

			// const response = await fetch(this.url)
			// const arrayBuffer = await response.arrayBuffer()
			// this.audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer)
			// this.isLoaded = true

			fetch(this.url)
				.then((res) => res.arrayBuffer())
				.then((buffer) => this.audioContext.decodeAudioData(buffer))
				.then((audio) => resolve(audio))
				.catch((e) => console.error(`ERROR LOADING DATA, ${e}`))
		})
	}

	/**
	 * Begins playing the loaded audio
	 * @param offset the audio offset - if the playback needs to start after the start of the actual audio
	 * @param startIn waits this
	 */
	async playNow(offset: number = 0) {
		// Ensure the audio context is running

		if (this.audioContext.state === "suspended") {
			await this.audioContext.resume()
		}

		// Wait for the audio to be loaded

		if (!this.isLoaded) await this.loadAudio()
		if (!this.audioBuffer) return

		// Stop playback if already playing

		if (this.isPlaying) await this.stop()

		this.sourceNode = this.audioContext.createBufferSource()
		this.sourceNode.buffer = this.audioBuffer
		this.sourceNode.loop = false
		this.sourceNode.connect(this.audioContext.destination)

		let startTime = this.audioContext.currentTime
		let playbackOffset = 0

		if (offset >= 0) {
			// Start immediately, offset into the audio buffer
			playbackOffset = offset
		} else {
			// Schedule playback to start after 'abs(offset)' seconds
			startTime += Math.abs(offset)
		}

		const duration = this.audioBuffer.duration - playbackOffset
		if (duration <= 0) {
			// No audio left to play

			return
		}

		this.sourceNode.start(startTime, playbackOffset, duration)
		this.isPlaying = true

		this.sourceNode.onended = () => {
			this.isPlaying = false
			this.sourceNode?.disconnect()
			this.sourceNode = null
		}
	}

	// async playNow(offset: number = 0, startIn: number = 0) {
	// 	// if still loading this audio

	// 	console.log(`PLAY NOW FOR ${this.name} <${this.id}>`)
	// 	console.log(`AudioContext state`, this.audioContext.state)

	// 	const now = DateTime.now()

	// 	if (this.audioContext.state === "suspended") {
	// 		await this.audioContext.resume()
	// 	}

	// 	if (!this.isLoaded) await this.loadAudio()
	// 	if (!this.audioBuffer) return

	// 	console.warn("before is playing stop", this.isPlaying)
	// 	if (this.isPlaying) await this.stop()
	// 	console.warn("after is playing stop", this.isPlaying)

	// 	const after = DateTime.now()
	// 	let playOffset: number = after.diff(now).as("seconds")

	// 	let startTime = startIn
	// 	if (offset < 0) startTime += Math.abs(offset)
	// 	else playOffset += offset

	// 	console.warn("DURATION", this.audioBuffer.duration)
	// 	console.warn("play offset", playOffset)

	// 	this.sourceNode = this.audioContext.createBufferSource()
	// 	this.sourceNode.loop = false
	// 	this.sourceNode.buffer = this.audioBuffer

	// 	this.sourceNode.connect(this.audioContext.destination)

	// 	const duration = this.audioBuffer.duration - playOffset
	// 	if (duration < 0) return

	// 	this.sourceNode.start(startTime, playOffset, duration)
	// 	this.isPlaying = true

	// 	this.sourceNode.onended = () => {
	// 		this.isPlaying = false
	// 	}
	// }

	/**
	 * Stops the current playback
	 */
	async stop(): Promise<void> {
		return new Promise((res) => {
			if (!this.sourceNode) return res()

			this.sourceNode.onended = () => {
				this.isPlaying = false
				this.sourceNode?.disconnect()
				this.sourceNode = null
				res()
			}

			this.sourceNode.stop()

			setTimeout(() => {
				res()
				// if shit hasn't happened in 2 seconds, just assume it stopped
				// this is a shitty attempt to fix ios issues
			}, 2000)
		})
	}

	/**
	 * Public accessor for the audio buffer
	 * @returns the audio buffer
	 */
	getAudioBuffer(): AudioBuffer | null {
		return this.audioBuffer
	}

	/**
	 * Gets the total duration of the source audio
	 * @returns the duration in seconds
	 */
	getDuration(): number {
		return this.audioBuffer?.duration ?? 0
	}
}
