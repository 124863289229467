<script lang="ts">
	import LuxedoLogo from "../../assets/icons/LuxedoLogo.svg"
	import { RadioController } from "../radio/RadioController"

	let audioCode: string | undefined
	RadioController.subscribe((ctx) => {
		audioCode = ctx.audioCode
	})
</script>

<div class="flex-column">
	<header>
		<img src={LuxedoLogo} alt="Luxedo Radio Logo" />
		<h1>Radio Player</h1>
	</header>
</div>

<style>
	header {
		z-index: 10;
		height: 4rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 1rem 0;
		margin: 1rem 0 0 0;
		cursor: pointer;
	}

	h1 {
		font-size: var(--h1);
		margin: 0 1rem;
		width: 4rem;
		line-height: 1em;
	}
	@media only screen and (max-width: 800px) {
		header {
			flex-direction: column;
			padding: 1rem 10%;
			height: 6rem;
		}

		img {
			height: 2rem;
		}

		h1 {
			width: unset;
			margin-top: 1rem;
		}
	}
</style>
