<script lang="ts">
</script>

<div class="loading-bar"></div>

<style>
	@keyframes loading {
		0% {
			left: -100%;
		}

		50% {
			left: 100%;
		}

		100% {
			left: -100%;
		}
	}

	.loading-bar {
		position: absolute;
		bottom: -0.25rem;
		left: 0;
		border-radius: var(--br);
		height: 0.5rem;

		width: 100%;
		background-color: var(--color-main);

		animation: loading infinite 2s;
	}
</style>
