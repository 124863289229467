<script lang="ts">
	import LuxedoLogo from "../../assets/icons/LuxedoLogo.svg"
</script>

<footer>
	Powered by <a href="https://luxedo.com">
		<img src={LuxedoLogo} alt="Luxedo" />
	</a>.
</footer>

<style>
	footer {
		z-index: 10;
		text-align: center;
		padding-bottom: 0.5rem;
	}

	a {
		border-bottom: 0;
		width: fit-content;
		height: fit-content;
	}

	footer {
		color: var(--color-text);
	}

	img {
		height: 1rem;
		margin-right: -0.1rem;
		margin-left: 0.25rem;
	}
</style>
