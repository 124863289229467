<script lang="ts">
</script>

<div class="need-click-overlay" on:click|stopPropagation on:touchstart|stopPropagation>
	<p>Click anywhere to begin listening.</p>
</div>

<style>
	.need-click-overlay {
		position: absolute;
		top: 0;
		left: 0;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 100vw;
		height: 100vh;

		backdrop-filter: blur(1rem);
		z-index: 1;
	}
</style>
