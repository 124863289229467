<script lang="ts">
	import { DateTime } from "luxon"
	import { RadioController, ResponseError, type RadioControllerContext } from "./RadioController"
	import LoadingBar from "./LoadingBar.svelte"
	import { ArrowForward, PlusIcon, RefreshIcon } from "svelte-comps/icons"

	let code: string | undefined
	let playing: string | undefined

	let nextName: string | undefined

	let isLoading: boolean = false
	let errorMessage: string | undefined

	let lastUpdate: number | undefined // Last update in seconds past
	let lastUpdateTimestamp: number | undefined
	let timestampInterval: number

	let playbackOffset: number = 0

	RadioController.subscribe(onDataUpdate)

	function onDataUpdate(ctx: RadioControllerContext) {
		code = ctx.audioCode
		playing = ctx.playing
		nextName = ctx.nextName
		lastUpdateTimestamp = ctx.lastUpdate
		playbackOffset = ctx.playbackOffset
		updateLastUpdate(lastUpdateTimestamp)
	}

	function updateLastUpdate(timestamp?: number) {
		if (timestampInterval) clearInterval(timestampInterval)
		if (!timestamp) return (lastUpdate = undefined)

		const update = () => {
			const last = DateTime.fromSeconds(timestamp)
			const now = DateTime.now()
			lastUpdate = Math.floor(now.diff(last).as("seconds"))
		}

		update()

		timestampInterval = setInterval(() => {
			update()
		}, 1000)
	}

	function handleHold(forward: boolean = true) {
		const changeOffset: number = forward ? 0.05 : -0.05

		let interval: number
		let hasTriggeredOnce: boolean = false // in case the interval gets cleared before triggering, the offset still needs to change for the single click 
		return (e: TouchEvent | MouseEvent) => {
			const target = e.target

			const onRelease = () => {
				if (!hasTriggeredOnce) RadioController.changeOffset(changeOffset)
				clearInterval(interval)
				target?.removeEventListener("mouseup", onRelease)
				target?.removeEventListener("touchend", onRelease)
			}

			target?.addEventListener("mouseup", onRelease)
			target?.addEventListener("touchend", onRelease)

			interval = setInterval(() => {
				hasTriggeredOnce = true
				RadioController.changeOffset(changeOffset)
			}, 250)
		}
	}

	async function disconnect() {
		isLoading = true
		await RadioController.disconnect()
		isLoading = false
	}

	async function sync() {
		isLoading = true
		try {
			errorMessage = undefined
			await RadioController.resync()
		} catch (e) {
			if (e instanceof ResponseError) errorMessage = e.message
		}
		isLoading = false
	}
</script>

{#if code}
	<div class="card">
		<div class="flex-row space-apart">
			<div class="connected-to">
				<span class="small heading">Connected to</span>
				<div class="flex-row center-ver">
					<h3 class="audio-code">{code}</h3>
					<button
						class="small icon"
						id="disconnect-button"
						on:click={disconnect}
						title="Disconnect"
						disabled={isLoading}><PlusIcon /></button
					>
				</div>
			</div>
			<span class="last-update">
				{#if lastUpdate === undefined}
					loading...
				{:else}
					refreshed {lastUpdate} seconds ago
				{/if}
			</span>
		</div>

		<div class="current-playback">
			<span class="heading">Currently Playing</span>

			<h3>{playing ?? "nothing"}</h3>
		</div>

		<div class="info {errorMessage ? 'error' : ''}">
			{#if errorMessage}
				{errorMessage}
			{:else if nextName}
				Next up... {nextName}
			{/if}
		</div>

		<div class="player-bottom">
			<span class="playback-offset">
				{#if playbackOffset}
					playback offset: {playbackOffset}s
				{/if}
			</span>
			<div class="button-container">
				<!-- on:click={() => RadioController.changeOffset(-0.05)} -->
				<button
					class="offset-button icon"
					id="offset-back-button"
					on:touchstart={handleHold(false)}
					on:mousedown={handleHold(false)}
				>
					<RefreshIcon />
				</button>
				<button class="refresh outline-button" on:click={sync} disabled={isLoading}>Re-Sync</button>

				<!-- on:click={() => RadioController.changeOffset(0.05)} -->
				<button
					on:touchstart={handleHold(true)}
					on:mousedown={handleHold(true)}
					class="offset-button icon"
					id="offset-forward-button"
				>
					<RefreshIcon />
				</button>
			</div>
		</div>

		{#if isLoading}
			<LoadingBar />
		{/if}
	</div>
{/if}

<style>
	#disconnect-button {
		padding: 0;
		margin-left: 0.25rem;
	}

	#disconnect-button :global(svg) {
		rotate: 45deg;
		height: 1.5em;
	}

	#disconnect-button :global(.svg-stroke) {
		stroke: var(--ct-red);
	}

	#offset-forward-button :global(svg) {
		transform: rotateY(180deg);
	}

	.button-container button.offset-button {
		flex-grow: unset;
	}

	.current-playback h3 {
		margin-left: -0.05rem;
	}
</style>
