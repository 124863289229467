import { DateTime } from "luxon"

// import { AudioPlayer, extractIdFromUrl } from "./AudioPlayer"
import { AudioPlayer } from "./AudioPlayer"

import type { AudioBlockData, AudioBlockInstance } from "../types/RadioTypes"

type TimeoutInfo = { start: number; timeoutId: number; showId: number; offset: number }
type PlaybackCallback = (name?: string, player?: AudioPlayer) => void

export class AudioScheduler {
	declare _player: AudioPlayer | undefined
	declare playbackStart: number
	declare playbackShowId: number

	declare previousData: AudioBlockData

	declare timeout: TimeoutInfo | undefined
	declare offset: number

	declare playbackCallback: PlaybackCallback
	declare scheduleCallback: PlaybackCallback

	constructor(onPlayback: PlaybackCallback, onSchedule: PlaybackCallback) {
		this.offset = 0

		this.playbackCallback = onPlayback
		this.scheduleCallback = onSchedule
	}

	/**
	 * Called when new data was retrieved from the server.
	 * Syncs current playback and schedules upcoming playback.
	 * @param data the new AudioBlockData
	 */
	updatePlaybackData(data: AudioBlockData, forceSync?: boolean) {
		const current = data.current
		const next = data.upcoming[0]

		if (next) this.schedulePlayback(next)
		else this.clearSchedule()
		if (current && (!(current.start == this.playbackStart) || forceSync)) {
			this.syncPlayback(data.current, forceSync)
		} else if (!current) this.stopPlayback()

		this.previousData = data
	}

	// #region xxxxxxxxxxxxxxxxxxx PLAYBACK     xxxxxxxxxxxxxxxxxxx

	/**
	 * Syncs current playback with the specified playback data
	 * @param data the AudioBlockInstance for the current playback
	 */
	async syncPlayback(data: AudioBlockInstance, forceSync?: boolean) {
		// Clear any pending scheduled playback

		console.warn("SYNC PLAYBACK")

		let offset = -AudioScheduler.calculateDiff(data.start).as("seconds") + this.offset

		if (!this.player || this.playbackShowId != data.id) {
			const prevPlayer = this.player

			if (data.id == -1) {
				this.player = undefined
			} else {
				const now = DateTime.now()
				this.player = new AudioPlayer(data.url, data.name)
				await this.player.loadAudio()
				const afterLoad = DateTime.now()

				offset += afterLoad.diff(now).as("seconds")

				if (offset > this.player.getDuration()) {
					// if the offset is greater than the show duration, the reported current playback is likely not in sync with the acutal device playback
					this.player = prevPlayer
					return
				}

				prevPlayer?.stop()
				this.player.playNow(offset) // Start playback immediately
			}
		} else if (this.playbackStart != data.start || forceSync) {
			if (offset > this.player.getDuration()) return

			this.player.playNow(offset)
		} else return

		this.playbackShowId = data.id
		this.playbackStart = data.start
	}

	/**
	 * Schedules audio to play at the start time specified in the data block
	 * @param data the AudioBlockInstance for the upcoming playback
	 */
	schedulePlayback(data: AudioBlockInstance) {
		if (
			this.timeout &&
			this.timeout.start == data.start &&
			this.timeout.showId == data.id &&
			this.timeout.offset == this.offset
		)
			return
		else if (this.timeout) clearTimeout(this.timeout.timeoutId)

		const diffDuration = AudioScheduler.calculateDiff(data.start)
		const timeoutMilliseconds = diffDuration.as("milliseconds") - (this.offset * 1000)

		if (timeoutMilliseconds <= 0) return

		// Schedule future playback

		let nextPlayer: AudioPlayer | undefined
		if (data.id == -1) {
			nextPlayer = undefined
		} else {
			nextPlayer = new AudioPlayer(data.url, data.name)
		}

		this.scheduleCallback(nextPlayer?.name ?? "nothing", nextPlayer)

		const timeoutId = window.setTimeout(() => {
			this.player?.stop()
			this.player = nextPlayer
			this.playbackStart = data.start
			this.playbackShowId = data.id
			this.player?.playNow()

			nextPlayer = undefined
		}, timeoutMilliseconds)

		this.timeout = {
			start: data.start,
			showId: data.id,
			timeoutId,
			offset: this.offset,
		}
	}

	/**
	 * Sets the playback offset to the specified value to add or remove delay before starting / syncing playback
	 * @param offset the new playback offset
	 */
	setOffset(offset: number) {
		console.log("NEW SCHEDULER OFFSET", offset)

		this.offset = offset
		this.clearSchedule()
		this.updatePlaybackData(this.previousData, true)
	}

	clearSchedule() {
		if (this.timeout) clearTimeout(this.timeout.timeoutId)
		this.timeout = undefined
	}

	stopPlayback() {
		this._player?.stop()
		this.player = undefined
	}

	// #endregion xxxxxxxxxxxxxxxx PLAYBACK     xxxxxxxxxxxxxxxxxxx
	// #region xxxxxxxxxxxxxxxxxxx GET AND SET  xxxxxxxxxxxxxxxxxxx

	/**
	 * Sets the player and calls the `playbackCallback`
	 */
	set player(audioPlayer: AudioPlayer | undefined) {
		this._player = audioPlayer
		this.playbackCallback(this._player?.name, this._player)
	}

	/**
	 * Gets the current player
	 */
	get player(): AudioPlayer | undefined {
		return this._player
	}

	// #endregion xxxxxxxxxxxxxxxx GET AND SET  xxxxxxxxxxxxxxxxxxx
	// #region xxxxxxxxxxxxxxxxxxx STATIC UTILS xxxxxxxxxxxxxxxxxxx

	/**
	 * Calculates the difference from now to the specified start time
	 * @param startTime the start time to compare against now
	 * @returns a duration object representing the difference
	 */
	private static calculateDiff(startTime: number) {
		const start = DateTime.fromSeconds(startTime)
		const now = DateTime.now()
		return start.diff(now)
	}

	// #endregion xxxxxxxxxxxxxxxx STATIC UTILS xxxxxxxxxxxxxxxxxxx
}
