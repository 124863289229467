<script lang="ts">
	import { onMount } from "svelte"
	import Header from "./reusable/Header.svelte"
	import Footer from "./reusable/Footer.svelte"
	import { RadioController } from "./radio/RadioController"
	import RadioPlayer from "./radio/RadioPlayer.svelte"
	import { Overlay } from "svelte-comps/overlay"

	export let id: string | undefined = undefined

	$: id && RadioController.connectToAudioCode(id)
</script>

<svelte:head>
	<script src="https://use.ntpjs.org/ntp.js" async defer></script>
</svelte:head>

<Header />
<main>
	<RadioPlayer />
</main>
<Footer />

<Overlay />

<style>
</style>
